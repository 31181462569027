import {
    BuyProCheckout,
    BuyProConfirmation,
    BuyProIndividualProducts,
    BuyProLoading,
    BuyProPresets,
    EcommerceCheckoutWrapperComponent,
    EcommerceIndividualProducts,
    EcommerceOrderConfirmationComponent,
    EcommerceOrderLoadingComponent,
    EcommercePresets,
} from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { AuditDialogComponentLegacy } from '../../component/audit/AuditDialogComponentLegacy';
import { AuditPageWithProvider } from '../../component/audit/AuditPage';
import { AuditLegacyRoutingRedirectWithProvider } from '../../component/audit/AuditRedirect';
import { ConfigureSSOComponent } from '../../component/authsettings/subcomponents/ConfigureSSOComponent';
import { CreateEditSAMLProvisioningRuleComponentWithParams } from '../../component/authsettings/subcomponents/CreateEditSAMLProvisioningRuleComponent';
import EditPasswordPolicyComponent from '../../component/authsettings/subcomponents/EditPasswordPolicyComponent';
import { SAMLProvisioningRulesComponentWithProviders } from '../../component/authsettings/subcomponents/SAMLProvisioningRulesComponent';
import EcommerceComponent from '../../component/ecommerce/EcommerceComponent';
import EcommerceManagePlanComponent from '../../component/ecommerce/EcommerceManagePlanComponent';
import EcommerceMonthlyUpsellComponent from '../../component/ecommerce/EcommerceMonthlyUpsellComponent';
import { ExternalAppsWithProvider } from '../../component/externalApps/ExternalApps';
import ExternalApplicationsPATSettingsDrawerComponent from '../../component/externalApps/pat/ExternalApplicationsPATSettingsDrawerComponent';
import
{ CreateEditExternalApplicationsWithProviders } from '../../component/externalApps/subcomponents/CreateEditExternalApplicationsPageComponent';
import
{ ExternalApplicationsScopesDrawerComponentWithParams } from '../../component/externalApps/subcomponents/ExternalApplicationsScopesDrawerComponent';
import { LicensesWithParams } from '../../component/licensing/Licenses';
import ActivateLicenseOfflineComponent from '../../component/licensing/subcomponents/ActivateLicenseOfflineComponent';
import ActivateLicenseOnlineComponent from '../../component/licensing/subcomponents/ActivateLicenseOnlineComponent';
import { AddEditGroupAllocationComponentWithParams } from '../../component/licensing/subcomponents/AddEditGroupAllocationComponent';
import AddUserAllocationComponent from '../../component/licensing/subcomponents/AddUserAllocationComponent';
import DeactivateLicenseOfflineComponent from '../../component/licensing/subcomponents/DeactivateLicenseOfflineComponent';
import EcommerceSuccessComponent from '../../component/licensing/subcomponents/EcommerceSuccessComponent';
import EditUserAllocationComponent from '../../component/licensing/subcomponents/EditUserAllocationComponent';
import InheritedLicenseComponent from '../../component/licensing/subcomponents/InheritedLicenseComponent';
import ViewUserAllocationsInGroupComponent from '../../component/licensing/subcomponents/ViewUserAllocationsInGroupComponent';
import UpgradeToEnterpriseDialogComponent from '../../component/licensing/UpgradeToEnterpriseDialogComponent';
import { OrganizationEmailSettingsRevampComponentWithProvider } from '../../component/organizationsettings/email/OrganizationEmailSettingsRevampComponent';
import OrganizationAdminHomeComponent from '../../component/organizationsettings/OrganizationAdminHome';
import { OrganizationSettingsPageComponentWithProvider } from '../../component/organizationsettings/OrganizationSettingsPageComponent';
import AccountLogicalNameWarningDialogComponent
    from '../../component/organizationsettings/subcomponents/AccountLogicalNameWarningDialogComponent';
import AddOrganizationAccessPolicyMembersDrawerComponent
    from '../../component/securitySettings/accessPolicy/add/AddOrganizationAccessPolicyMembersDrawerComponent';
import { AttributeMappingPageComponentWithProviders } from '../../component/securitySettings/attributeMapping/AttributeMappingPageComponent';
import { ConfigureSSOPageWithProvider } from '../../component/securitySettings/ConfigureSSOPage';
import { SecuritySettingsPageComponentWithParams } from '../../component/securitySettings/SecuritySettings';
import { AddEditTenantTagsLabelsComponentWithProvider } from '../../component/tags/addedit/labels/AddEditTenantTagsLabelsComponent';
import { AddEditTenantTagsPropertiesComponentWithProvider } from '../../component/tags/addedit/properties/AddEditTenantTagsPropertiesComponent';
import { AddEditTenantTagsPropertiesDrawerWithProvider } from '../../component/tags/addedit/properties/AddEditTenantTagsPropertiesDrawer';
import { TenantTagsComponentWithProvider } from '../../component/tags/base/TenantTagsViewComponent';
import TenantCreateComponent from '../../component/tenants/create/TenantCreateComponent';
import TenantAdminHome from '../../component/tenants/home/TenantAdminHome';
import { TenantServicesAddComponent } from '../../component/tenants/services/TenantServicesAddComponent';
import { TenantServicesComponentWithProvider } from '../../component/tenants/services/TenantServicesComponent';
import { TenantSettingsComponentWithProvider } from '../../component/tenants/settings/TenantSettingsComponent';
import ConfigureTenantLicensesComponent from '../../component/tenants/subcomponents/ConfigureTenantLicenseComponent';
import LogExportComponent from '../../component/tenants/subcomponents/LogExportComponent';
import SkipServiceConfirmationDialogComponent from '../../component/tenants/subcomponents/SkipServiceConfirmationDialogComponent';
import { ServicesUsagePageComponentWithProviders } from '../../component/tenants/subcomponents/usage/ServicesUsagePageComponent';
import { AddEditRobotAccountComponentWithParams } from '../../component/users/subcomponents/AddEditRobotAccountComponent';
import AddEditUserComponent from '../../component/users/subcomponents/AddEditUserComponent';
import BulkInviteUserComponent from '../../component/users/subcomponents/BulkInviteUserComponent';
import { CreateEditGroupComponentWithParams } from '../../component/users/subcomponents/CreateEditGroupComponent';
import { UsersPageComponentWithProvider } from '../../component/users/UsersPageComponent';
import { accountLogicalName } from '../../store/selectors';
import CheckGuard from '../helpers/CheckGuard';

const useOrgAdminPrivateShellRoutes = () => {
    const EnableEcommerceIntegration = useFeatureFlagValue(Features.EnableEcommerceIntegration.name);
    const EnableEcommercePlanSelectionRevampM0 = useFeatureFlagValue(Features.EnableEcommercePlanSelectionRevampM0.name);
    const EnableAttributeMapping = useFeatureFlagValue(Features.EnableAttributeMapping.name);
    const EnableUnifiedAuditExperience = useFeatureFlagValue(Features.EnableUnifiedAuditExperience.name);

    const {
        isAccountLicenseEnterpriseOrPro, isPro,
    } = useCheckLicense();
    const accountName = useSelector(accountLogicalName);

    const getRoute = useRouteResolver();

    return useMemo<RouteObject[]>(() => [
        {
            path: RouteNames.TenantSettings,
            element: <TenantSettingsComponentWithProvider />,
        },
        {
            path: RouteNames.TenantServices,
            element: <TenantServicesComponentWithProvider />,
            children: [
                {
                    path: RouteNames.TenantServicesAdd,
                    element: <TenantServicesAddComponent />,
                },
                {
                    path: RouteNames.TenantConfigureService,
                    element: <ConfigureTenantLicensesComponent />,
                },
                {
                    path: RouteNames.TenantServiceLogExport,
                    element: <LogExportComponent />,
                },
            ],
        },
        {
            path: RouteNames.TenantTagsPropertiesEdit,
            element: <AddEditTenantTagsPropertiesComponentWithProvider />,
            children: [
                {
                    path: RouteNames.TenantTagsPropertiesDrawerEdit,
                    element: <AddEditTenantTagsPropertiesDrawerWithProvider />,
                },
            ],
        },
        {
            path: RouteNames.TenantTagsPropertiesAdd,
            element: <AddEditTenantTagsPropertiesComponentWithProvider />,
            children: [
                {
                    path: RouteNames.TenantTagsPropertiesDrawerAdd,
                    element: <AddEditTenantTagsPropertiesDrawerWithProvider />,
                },
            ],
        },
        {
            path: RouteNames.TenantTagsLabelsEdit,
            element: <AddEditTenantTagsLabelsComponentWithProvider />,
        },
        {
            path: RouteNames.TenantTagsLabelsAdd,
            element: <AddEditTenantTagsLabelsComponentWithProvider />,
        },
        {
            path: RouteNames.TenantTags,
            children: [
                {
                    path: RouteNames.TenantTagsLabels,
                    element: <TenantTagsComponentWithProvider />,
                },
                {
                    path: RouteNames.TenantTagsProperties,
                    element: <TenantTagsComponentWithProvider />,
                },
            ],
        },
        {
            path: RouteNames.TenantAudit,
            element: CheckGuard(
                EnableUnifiedAuditExperience,
                <AuditPageWithProvider />,
            ),
        },
        {
            path: RouteNames.TenantHome,
            element: <TenantAdminHome />,
        },
        {
            path: RouteNames.Services,
            element: <Navigate to={getRoute(RouteNames.OrganizationAdminHome)} />,
            children: [
                {
                    path: RouteNames.TenantConfigureSkip,
                    component: SkipServiceConfirmationDialogComponent,
                },
                {
                    path: RouteNames.TenantConfigure,
                    component: ConfigureTenantLicensesComponent,
                },
                {
                    path: RouteNames.OrchLogExport,
                    component: LogExportComponent,
                },
            ],
        },
        {
            path: RouteNames.Users,
            element: <UsersPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.UserAddEdit,
                    element: <AddEditUserComponent />,
                },
                {
                    path: RouteNames.UsersUserAddLicenseAllocation,
                    element: <AddUserAllocationComponent />,
                },
                {
                    path: RouteNames.UsersUserEditLicenseAllocation,
                    element: <EditUserAllocationComponent />,
                },
                {
                    path: RouteNames.UsersUserLicenseInheritance,
                    element: <InheritedLicenseComponent />,
                },
                {
                    path: RouteNames.BulkUserInvite,
                    element: <BulkInviteUserComponent />,
                },
            ],
        },
        {
            path: RouteNames.SecuritySettingsConfigure,
            element: <ConfigureSSOPageWithProvider />,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRules,
            element: <SAMLProvisioningRulesComponentWithProviders />,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRulesAdd,
            element: <CreateEditSAMLProvisioningRuleComponentWithParams />,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRulesEdit,
            element: <CreateEditSAMLProvisioningRuleComponentWithParams />,
        },
        {
            path: RouteNames.SecuritySettingsAttributeMapping,
            element:
                CheckGuard(
                    EnableAttributeMapping,
                    <AttributeMappingPageComponentWithProviders />
                ),
        },
        {
            path: RouteNames.Groups,
            element: <UsersPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.GroupsEdit,
                    element: <CreateEditGroupComponentWithParams />,
                },
                {
                    path: RouteNames.GroupsAdd,
                    element: <CreateEditGroupComponentWithParams />,
                },
                {
                    path: RouteNames.GroupAllocationRule,
                    element: <AddEditGroupAllocationComponentWithParams />,
                },
            ],
        },
        {
            path: RouteNames.Robots,
            element: <UsersPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.RobotsAddEdit,
                    element: <AddEditRobotAccountComponentWithParams />,
                },
            ],
        },
        {
            path: RouteNames.Licensing,
            element: <LicensesWithParams />,
            children: [
                {
                    path: RouteNames.LicensingUpgradeToEnterprise,
                    element: <UpgradeToEnterpriseDialogComponent />,
                },
                {
                    path: RouteNames.LicensingUserAddLicenseAllocation,
                    element: <AddUserAllocationComponent />,
                },
                {
                    path: RouteNames.LicensingUserEditLicenseAllocation,
                    element: <EditUserAllocationComponent />,
                },
                {
                    path: RouteNames.LicensingUserLicenseInheritance,
                    element: <InheritedLicenseComponent />,
                },
                {
                    path: RouteNames.LicensingGroupAllocationAddEdit,
                    element: <AddEditGroupAllocationComponentWithParams />,
                },
                {
                    path: RouteNames.LicensingGroupAllocationView,
                    element: <ViewUserAllocationsInGroupComponent />,
                },
                {
                    path: RouteNames.LicensingTenantConfigure,
                    element: <ConfigureTenantLicensesComponent />,
                },
                {
                    path: RouteNames.ActivateOffline,
                    element: <ActivateLicenseOfflineComponent />,
                },
                {
                    path: RouteNames.BuyProSuccess,
                    element: CheckGuard(EnableEcommerceIntegration, <EcommerceSuccessComponent />),
                },
                {
                    path: RouteNames.LicensingActivateLicenseOnline,
                    element: isPro ? <EcommerceMonthlyUpsellComponent /> : <ActivateLicenseOnlineComponent />,
                },
                {
                    path: RouteNames.DeactivateOffline,
                    element: <DeactivateLicenseOfflineComponent />,
                },
            ],
        },
        {
            path: RouteNames.AuthSettingsDeprecated,
            element: <Navigate to={RouteNames.SecuritySettings.replace(':accountName', accountName)} />,
        },
        {
            path: RouteNames.AuthSettingsDeprecatedConfigure,
            element: <Navigate to={RouteNames.SecuritySettingsConfigure.replace(':accountName', accountName).replace(':type', 'aad')} />,
        },
        {
            path: RouteNames.OrganizationAdminHome,
            element: <OrganizationAdminHomeComponent />,
        },
        {
            path: RouteNames.TenantCreate,
            element: <TenantCreateComponent />,
        },
        {
            path: BuyProCheckout,
            element:
                CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(),
                    <EcommerceCheckoutWrapperComponent />,
                ),
        },
        {
            path: BuyProLoading,
            element: CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(), <EcommerceOrderLoadingComponent />),
        },
        {
            path: BuyProConfirmation,
            element:
                CheckGuard(EnableEcommerceIntegration,
                    <EcommerceOrderConfirmationComponent />,
                ),
        },
        {
            path: RouteNames.BuyPro,
            element: EnableEcommercePlanSelectionRevampM0 ? (
                <Navigate to={RouteNames.BuyProPresets.replace(':accountName', accountName)} />
            ) :
                CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(), <EcommerceComponent />),
        },
        {
            path: RouteNames.BuyEnterprise,
            element: EnableEcommercePlanSelectionRevampM0 ? (
                <Navigate to={RouteNames.BuyProPresets.replace(':accountName', accountName)} />
            ) :
                CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(), <EcommerceComponent />),
        },
        {
            path: BuyProPresets,
            element:
                !EnableEcommercePlanSelectionRevampM0
                    ? <Navigate to={RouteNames.BuyPro.replace(':accountName', accountName)} />
                    : CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(),
                        <EcommercePresets isDirectBuyFlow={false} />),
        },
        {
            path: BuyProIndividualProducts,
            element:
                CheckGuard(EnableEcommercePlanSelectionRevampM0 && EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(),
                    <EcommerceIndividualProducts isDirectBuyFlow={false} />),
        },
        {
            path: RouteNames.ManagePlan,
            element:
                CheckGuard(
                    EnableEcommerceIntegration && isAccountLicenseEnterpriseOrPro(),
                    <EcommerceManagePlanComponent />,
                ),
        },
        {
            path: RouteNames.OrganizationSettings,
            element: <OrganizationSettingsPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.OrganizationSettingsWarning,
                    element: <AccountLogicalNameWarningDialogComponent />,
                },
            ],
        },
        {
            path: RouteNames.OrganizationSettingsAdvanced,
            element: <OrganizationSettingsPageComponentWithProvider />,
        },
        {
            path: RouteNames.EmailSettings,
            element: <OrganizationEmailSettingsRevampComponentWithProvider />,
        },
        {
            path: RouteNames.LoginCustomization,
            element: <OrganizationSettingsPageComponentWithProvider />,
        },
        {
            path: RouteNames.AuditLogs,
            element: <AuditLegacyRoutingRedirectWithProvider />,
            children: [
                {
                    path: RouteNames.AuditLogDialog,
                    element: <AuditDialogComponentLegacy />,
                },
            ],
        },
        {
            path: RouteNames.Audit,
            element: <AuditLegacyRoutingRedirectWithProvider unifiedAuditRoute />,
        },
        {
            path: RouteNames.ExternalApplications,
            element: <ExternalAppsWithProvider />,
        },
        {
            path: RouteNames.ExternalApplicationsPAT,
            element: <ExternalAppsWithProvider />,
            children: [
                {
                    path: RouteNames.ExternalApplicationsPATSettings,
                    element: <ExternalApplicationsPATSettingsDrawerComponent />,
                },
            ],
        },
        {
            path: RouteNames.ExternalApplicationAdd,
            element: <CreateEditExternalApplicationsWithProviders />,
            children: [
                {
                    path: RouteNames.ExternalApplicationAddScopesWithName,
                    element: <ExternalApplicationsScopesDrawerComponentWithParams />,
                },
                {
                    path: RouteNames.ExternalApplicationAddScopes,
                    element: <ExternalApplicationsScopesDrawerComponentWithParams />,
                },
            ],
        },
        {
            path: RouteNames.ExternalApplicationEdit,
            element: <CreateEditExternalApplicationsWithProviders />,
            children: [
                {
                    path: RouteNames.ExternalApplicationEditScopesWithName,
                    element: <ExternalApplicationsScopesDrawerComponentWithParams />,
                },
                {
                    path: RouteNames.ExternalApplicationEditScopes,
                    element: <ExternalApplicationsScopesDrawerComponentWithParams />,
                },
            ],
        },
        {
            path: RouteNames.ServicesUsage,
            element: <ServicesUsagePageComponentWithProviders />,
            children: [
                {
                    path: RouteNames.TenantServicesUsageAllocation,
                    element: <ConfigureTenantLicensesComponent />,
                },
            ],
        },
        {
            path: RouteNames.SessionPolicy,
            element: <SecuritySettingsPageComponentWithParams />,
        },
        {
            path: RouteNames.OrganizationAccessPolicy,
            element: <SecuritySettingsPageComponentWithParams />,
            children: [
                {
                    path: RouteNames.OrganizationAccessPolicyAdd,
                    element: <AddOrganizationAccessPolicyMembersDrawerComponent />,
                },
            ],
        },
        {
            path: RouteNames.SecuritySettings,
            element: <SecuritySettingsPageComponentWithParams />,
            children: [
                {
                    path: RouteNames.ConfigureSSO,
                    element: <ConfigureSSOComponent />,
                },
                {
                    path: RouteNames.AuthSettingsPasswordPolicy,
                    element: <EditPasswordPolicyComponent />,
                },
            ],
        },
    ], [
        EnableAttributeMapping,
        EnableEcommerceIntegration,
        EnableEcommercePlanSelectionRevampM0,
        EnableUnifiedAuditExperience,
        accountName,
        getRoute,
        isAccountLicenseEnterpriseOrPro,
        isPro,
    ]);
};

export default useOrgAdminPrivateShellRoutes;
