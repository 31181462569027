import { getRegionTranslationId } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import {
    getFriendlyName,
    getIconName,
} from '../../../common/constants/ServicesMapping';
import { checkServiceRegion } from '../../../common/hooks/useCheckRegionService';
import type { IServiceMetadata } from '../interfaces/service';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        header: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        labelRoot: { alignItems: 'flex-start' },
        labelRootRevamp: {
            alignItems: 'center',
            border: '1px solid',
            borderRadius: '4px',
            margin: '0px',
        },
        labelHover: { '&:hover': { backgroundColor: `${theme.palette.semantic.colorBackgroundSecondary} !important` } },
        label: {
            display: 'inline-flex',
            padding: '9px',
            paddingTop: '10px',
        },
        labelRevamp: {
            display: 'flex',
            padding: '9px',
            alignItems: 'center',
            alignContent: 'center',
            width: '330px',
            height: '56px',
        },
        labelName: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        labelDescription: {
            display: 'block',
            flexDirection: 'column',
        },
        icon: { margin: '2px 8px 0 2px' },
    }),
}));

const TenantServiceCard: React.FC<{
    service: IServiceMetadata;
    clickable: boolean;
    cardChecked?: boolean;
    onChangeHandler?: () => void;
    TenantServiceErrorMessage?: any;
    region?: any;
    alternateColor?: boolean;
    validationErrors: any;
}> = ({
    service, clickable = true, cardChecked = false, onChangeHandler, TenantServiceErrorMessage, region, alternateColor, validationErrors,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const EnableInsightsForAll = useFeatureFlagValue(Features.EnableInsightsForAll.name);

    const requiredEnabledServiceIds: string[] = useMemo(() => {
        if (EnableInsightsForAll) {
            return [ 'insights' ];
        }
        return [];
    }, [ EnableInsightsForAll ]);

    const disabledCheckBox = useCallback((id: string) =>
        (validationErrors && validationErrors?.shouldDisableRevamp?.indexOf(id) > -1)
        || requiredEnabledServiceIds.includes(id)
    , [ requiredEnabledServiceIds, validationErrors ]);

    return (
        <FormControlLabel
            labelPlacement='start'
            control={clickable ?
                <Checkbox
                    checked={cardChecked}
                    onChange={onChangeHandler}
                /> : <></>}
            label={
                <div>
                    <div className={classes.labelName}>
                        <div className={classes.icon}>
                            <PortalCustomIcon
                                name={getIconName(service.id)} />
                        </div>
                        <UiText
                            className={classes.header}
                            style={{ fontWeight: 600 }}>
                            {getFriendlyName(service.id)}
                        </UiText>
                        {service.defaultRegion !== 'None'
                        && (!checkServiceRegion(service, region) || !clickable) &&
                        <Chip
                            label={translate({ id: getRegionTranslationId(service.defaultRegion) })}
                            className="info-mini"
                            style={{
                                marginLeft: '8px',
                                backgroundColor: alternateColor ? '#FFF3DB' : '',
                                color: alternateColor ? '#9E6100' : '',
                            }}
                            data-cy={`service-region-chip-${service.id}`}
                            size="small" />}
                        {TenantServiceErrorMessage}
                    </div>
                </div>
            }
            disabled={disabledCheckBox(service.id) || !clickable}
            classes={{
                root: clsx(classes.labelRootRevamp, !disabledCheckBox(service.id) && classes.labelHover),
                label: classes.labelRevamp,
            }}
            data-cy={`create-edit-tenant-option-${service.id}`}
        />
    );
};

export default TenantServiceCard;
