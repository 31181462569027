import { NavCustomizationSetting } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { Persona } from '@experiences/interfaces';
import { useLocalization } from '@experiences/locales';
import {
    HomePageEvent,
    portalTelemetry,
} from '@experiences/telemetry';
import {
    UiFeatureGuard,
    UiText,
} from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import Divider from '@mui/material/Divider';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApButton,
    ApLink,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    DeliveryType,
    ProductType,
    useDocumentationLinks,
} from '../../common/hooks/useDocumentationLink';
import useIsCustomizationElementVisible from '../../common/hooks/useIsCustomizationElementVisible';
import {
    Platform,
    usePlatformHost,
} from '../../common/hooks/usePlatformHost';
import type {
    sidebarLinkContainers,
    sidebarLinks,
} from '../../common/interfaces/sidebar';
import {
    getSidebarResource,
    sidebarURL,
} from '../../services/SidebarCMS';
import { SidebarBackup } from '../../services/utility/SidebarBackup';
import { SidebarK8s } from '../../services/utility/SidebarK8s';
import {
    accountLogicalName,
    persona,
} from '../../store/selectors';
import {
    SpacingToken,
    UiStack,
} from '../common/UiStack';
import { HomePageDownloadStudioButton } from './HomePageDownloadStudioButton';
import SubmitIdea from './SubmitIdea';

interface SideBarLinkProps {
    linkItem: sidebarLinks;
    dominantPersona: Persona;
}

const SideBarLink = ({
    linkItem, dominantPersona,
}: SideBarLinkProps) => {
    const { formatMessage: translate } = useIntl();
    const EnableFpsDocsUrl = useFeatureFlagValue(Features.EnableFpsDocsUrl.name);
    const accountName = useSelector(accountLogicalName);

    const translateSidebarText = useCallback((str: string) => translate({
        id: 'CLIENT_SIDEBAR_' + str,
        defaultMessage: str,
    }), [ translate ]);

    const linkHref = useMemo(() => {
        if (EnableFpsDocsUrl && linkItem.linkHref.startsWith('https://docs.uipath.com')) {
            return linkItem.linkHref.replace('https://docs.uipath.com', `/${accountName}/docs_`);
        }
        return linkItem.linkHref;
    }, [ EnableFpsDocsUrl, linkItem.linkHref, accountName ]);

    const handleClick = useCallback(() => {
        portalTelemetry.trackEvent({
            name: HomePageEvent.SideBarLinkClicked,
            properties: {
                Message: '[IN-HP-60] - Side bar link clicked',
                Code: '[IN-HP-60]',
                Persona: dominantPersona,
                Link: linkItem,
            },
        });

    }, [ dominantPersona, linkItem ]);

    return (
        <ApLink
            data-cy={`link-anchor-${linkItem.titleText}`}
            onClick={handleClick}
            target='_blank'
            href={linkHref}>
            {translateSidebarText(linkItem.linkText)}
        </ApLink>
    );
};

const StudioDownloadLinksContainerWrapper = ({ linkContainerId }: { linkContainerId: number }) => {
    const EnableStudioWebSideBar = useFeatureFlagValue(Features.EnableStudioWebSideBar.name);

    if (EnableStudioWebSideBar) {
        return <StudioDownloadLinksContainerV2 linkContainerId={linkContainerId} />;
    }
    return <StudioDownloadLinksContainer linkContainerId={linkContainerId} />;
};

const StudioDownloadLinksContainer = ({ linkContainerId }: { linkContainerId: number }) => {
    const { formatMessage: translate } = useIntl();
    const getLocalizedLinks = useDocumentationLinks();
    const dominantPersona = useSelector(persona);

    const link = useMemo(() => getLocalizedLinks({
        product: ProductType.STUDIO,
        delivery: DeliveryType.STANDALONE,
    }), [ getLocalizedLinks ]);

    const handleClick = useCallback(() => {

        portalTelemetry.trackEvent({
            name: HomePageEvent.SideBarStudioLinkClicked,
            properties: {
                Message: '[IN-HP-61] - Studio link clicked',
                Code: '[IN-HP-61]',
                Persona: dominantPersona,
                Link: link,
            },
        });

    }, [ link, dominantPersona ]);

    return (
        <UiStack
            align="start"
            direction="column"
            mt={linkContainerId === 0 ? 0 : SpacingToken.L}
            mb={SpacingToken.L}
            gap={SpacingToken.M}>
            <UiText
                data-cy="studio-header-linkscontainer"
                aria-level={3}
                variant={FontVariantToken.fontSizeH4Bold}>
                {translate({ id: 'CLIENT_STUDIO_HEADER' })}
            </UiText>
            <UiText data-cy="studio-subtext-linkscontainer">
                {translate({ id: 'CLIENT_STUDIO_SUBTEXT' }) + ' '}
                <ApLink
                    onClick={handleClick}
                    data-cy="learn-more-studio-link-linkscontainer"
                    target='_blank'
                    href={link}>
                    {translate({ id: 'CLIENT_LEARN_MORE' })}
                </ApLink>
            </UiText>
            <HomePageDownloadStudioButton />
        </UiStack>
    );
};

const StudioDownloadLinksContainerV2 = ({ linkContainerId }: { linkContainerId: number }) => {
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const host = usePlatformHost();

    const handleClick = useCallback(() => {
        const studioWeb = getRoute('/:accountName/studio_');

        portalTelemetry.trackEvent({
            name: HomePageEvent.SideBarStudioWebButtonClicked,
            properties: {
                Message: '[IN-HP-62] - Studio web button clicked',
                Code: '[IN-HP-62]',
            },
        });

        window.open(studioWeb);
    }, [ getRoute ]);

    return (
        <UiStack
            align="start"
            direction="column"
            mt={linkContainerId === 0 ? 0 : SpacingToken.L}
            mb={SpacingToken.L}
            gap={SpacingToken.M}>
            <UiText
                aria-level={3}
                variant={FontVariantToken.fontSizeH4Bold}>
                {translate({ id: 'CLIENT_STUDIO_HEADER' })}
            </UiText>
            <UiText variant={FontVariantToken.fontSizeM}>
                {translate({ id: 'CLIENT_STUDIO_WEB_SUBTEXT' })}
            </UiText>
            <ApButton
                variant="secondary"
                data-cy="home-page-sidebar-download-studio-button"
                onClick={handleClick}
                label={translate({ id: 'CLIENT_STUDIO_WEB_CTA' })} />
            {host === Platform.Windows && (
                <>
                    <UiText variant={FontVariantToken.fontSizeM}>
                        {translate({ id: 'CLIENT_STUDIO_DESKTOP' })}
                    </UiText>
                    <HomePageDownloadStudioButton asLink />
                </>
            )}
        </UiStack>
    );
};

export const HomePageSidebarLinksComponent = () => {
    const language = useLocalization();
    const { formatMessage: translate } = useIntl();
    const dominantPersona = useSelector(persona);
    const isDownloadsVisible = useIsCustomizationElementVisible(NavCustomizationSetting.ResourceCenterHidden);
    const EnableContentfulSideBar = useFeatureFlagValue(Features.EnableContentfulSideBar.name);
    const isSubmitIdeaVisible = useIsCustomizationElementVisible(NavCustomizationSetting.SubmitIdeaHidden);

    const {
        data, error,
    } = useSWR(
        EnableContentfulSideBar ? {
            language,
            dominantPersona,
            url: sidebarURL,
        } : null,
        getSidebarResource,
    );

    const translateSidebarText = useCallback((str: string) => translate({
        id: 'CLIENT_SIDEBAR_' + str,
        defaultMessage: str,
    }), [ translate ]);

    const sidebarData = useMemo(() => {
        if (!EnableContentfulSideBar) {
            return SidebarK8s.sidebarContent.sidebarLinkContainersCollection.items;
        } else if (error) {
            return SidebarBackup.sidebarContent.sidebarLinkContainersCollection.items;
        }
        return data?.sidebarContent?.sidebarLinkContainersCollection.items.filter(
            (obj: sidebarLinkContainers) => obj != null
        );
    }, [ EnableContentfulSideBar, data?.sidebarContent?.sidebarLinkContainersCollection.items, error ]);

    const downloadStudioPosition = useMemo(() => {
        if (!EnableContentfulSideBar) {
            return 0;
        } else if (error) {
            return SidebarBackup.sidebarContent.downloadStudioPosition;
        }
        return data?.sidebarContent?.downloadStudioPosition ?? 0;
    }, [ EnableContentfulSideBar, data?.sidebarContent?.downloadStudioPosition, error ]);

    const sidebarLinks = useCallback((linkContainerItem: sidebarLinkContainers, linkContainerId: number) => {
        if (linkContainerItem.enableShuffledEntries) {
            // eslint-disable-next-line spaced-comment
            linkContainerItem.sidebarLinksCollection?.items.sort(() => (Math.random() > .5) ? 1 : -1); //NOSONAR random is used to randomize array
        }
        return linkContainerItem.sidebarLinksCollection?.items.map(
            (
                linkItem: sidebarLinks,
                linkId: number,
            ) => (
                <UiStack
                    direction='column'
                    key={linkContainerItem.title + linkItem.titleText}
                    mt={SpacingToken.XS}>
                    <UiText data-cy={`link-titleText-${linkContainerId}-${linkId}`}>
                        {translateSidebarText(linkItem.titleText)}
                    </UiText>
                    <div data-cy={`link-linkText-${linkContainerId}-${linkId}`}>
                        <SideBarLink
                            linkItem={linkItem}
                            dominantPersona={dominantPersona} />
                    </div>
                </UiStack>
            ),
        );
    }, [ dominantPersona, translateSidebarText ]);

    return (
        <>
            {isSubmitIdeaVisible && (
                <UiFeatureGuard flagName={Features.EnableSubmitIdea.name}>
                    <SubmitIdea />
                </UiFeatureGuard>
            )}
            {sidebarData && sidebarData.length > 0 ? (
                sidebarData?.map(
                    (linkContainerItem: sidebarLinkContainers, linkContainerId: number) => (
                        <div key={linkContainerItem.title}>
                            {isDownloadsVisible && linkContainerId === downloadStudioPosition && (
                                <>
                                    <StudioDownloadLinksContainerWrapper linkContainerId={linkContainerId} />
                                    <Divider />
                                </>
                            )}
                            <UiStack
                                direction='column'
                                mt={linkContainerId === 0 && downloadStudioPosition !== 0 ? 0 : SpacingToken.L}
                                mb={SpacingToken.L}
                                gap={SpacingToken.XS}
                                data-cy={`link-container-${linkContainerId}`}>

                                {linkContainerItem.subtitle && (
                                    <UiText
                                        color="var(--color-foreground-de-emp)"
                                        variant={FontVariantToken.fontSizeMBold}
                                        data-cy={`link-subtitle-${linkContainerId}`}>
                                        {translateSidebarText(linkContainerItem.subtitle)}
                                    </UiText>
                                )}
                                <UiText
                                    variant={FontVariantToken.fontSizeH4Bold}
                                    aria-level={3}
                                    data-cy={`link-title-${linkContainerId}`}>
                                    {translateSidebarText(linkContainerItem.title)}
                                </UiText>
                                {sidebarLinks(linkContainerItem, linkContainerId)}
                            </UiStack>
                            { linkContainerId !== (sidebarData.length - 1) && <Divider /> }
                            {
                                isDownloadsVisible
                                    && linkContainerId === sidebarData.length - 1
                                    && downloadStudioPosition >= sidebarData.length
                                    && <>
                                        <Divider />
                                        <StudioDownloadLinksContainerWrapper linkContainerId={linkContainerId} />
                                    </>
                            }
                        </div>
                    ),
                )
            ) : (
                <div>
                    {isDownloadsVisible && sidebarData && sidebarData.length === 0 && <StudioDownloadLinksContainerWrapper linkContainerId={0} />}
                </div>
            )}
        </>
    );
};
