import { useLocalization } from '@experiences/locales';
import {
    useAuthContext,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import {
    ANNUAL_PLAN_TYPE,
    CART_ABANDONMENT_SOURCE_KEY,
    DIRECT_BUY_FLOW,
    getPriceString,
    MONTHLY_PLAN_TYPE,
    TRY_BUY_FLOW,
    useEcommerceEnabledCountries,
    useEcommerceTelemetry,
} from '../helpers/EcommerceHelpers';
import { BuyProPresets } from '../helpers/EcommerceRoutes';
import { useEcommerce } from '../helpers/useEcommerce';
import type { IPackage } from '../interfaces/ecommerce';
import {
    billingUrl,
    getPackagesConfigPublic,
} from '../services/BillingService';
import { useShowLearnMoreEcommerceDialog } from '../subcomponents/useShowLearnMoreEcommerceDialog';

const useEcommercePresetsViewModel = (
    currentAccountName: string,
    isDirectBuyFlow: boolean
) => {
    const { accountCountryCode } = useEcommerceEnabledCountries();
    const { token } = useAuthContext();
    const [ selectedPackageType, setSelectedPackageType ] = useState('SPATT');
    const packagesConfigUrl = `${billingUrl}/packagesConfig`;
    const [ currentCurrency, setCurrentCurrency ] = useState('USD');
    const language = useLocalization();
    const currentLocation = useLocation();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const logEcommerceEvent = useEcommerceTelemetry(isDirectBuyFlow);
    const sourceUrlParam = useMemo(
        () => new URLSearchParams(currentLocation.search).get(CART_ABANDONMENT_SOURCE_KEY),
        [ currentLocation.search ]
    );

    const {
        currentSkuPackageDetails, arePricesLoading: pricesLoading, productsPricesInAllCurrencies,
    } = useEcommerce();

    const {
        data: packagesData,
        isValidating: packagesLoading,
    } = useSWR(
        {
            accountName: currentAccountName,
            countryCode: accountCountryCode,
            currency: currentCurrency,
            url: packagesConfigUrl,
            accessToken: token,
        },
        ({
            url, accountName, accessToken, currency, countryCode,
        }) => {
            if (!currency) {
                return getPackagesConfigPublic({
                    url,
                    accountName,
                    countryCode,
                    accessToken,
                });
            }
            return getPackagesConfigPublic({
                url,
                accountName,
                countryCode,
                accessToken,
                currency,
            });
        },
    );
    const [ selectedPackage, setSelectedPackage ] = useState<IPackage | undefined>();

    useEffect(() => {
        if (packagesData) {
            setSelectedPackage(packagesData?.packages.find(lpsPackage => lpsPackage.type === selectedPackageType));
        }
    }, [ packagesData, selectedPackageType ]);

    useEffect(() => {
        if (isDirectBuyFlow) {
            const details = { Flow: DIRECT_BUY_FLOW };

            logEcommerceEvent('DirectBuy.ViewPlans', details);
        } else {
            const details = {
                Flow: TRY_BUY_FLOW,
                Source: 'DEFAULT',
            };

            // If the user is coming from a cart abandonment email, we need to log the event with the source as 'EMAIL'
            if (sourceUrlParam) {
                sessionStorage.setItem(CART_ABANDONMENT_SOURCE_KEY, sourceUrlParam);
                const route = getRoute(BuyProPresets);
                navigate(route);
                if (sourceUrlParam === 'EMAIL') {
                    details.Source = sourceUrlParam;
                }
            }

            if (sessionStorage.getItem(CART_ABANDONMENT_SOURCE_KEY) === 'EMAIL') {
                details.Source = sourceUrlParam ?? 'DEFAULT';
            }

            logEcommerceEvent('Licenses.ViewPlans', details);
        }
    }, [ sourceUrlParam, getRoute, logEcommerceEvent, navigate, isDirectBuyFlow ]);

    const onSelectOption = useCallback((packageType: string) => () => {
        setSelectedPackageType(packageType);
        setSelectedPackage(packagesData?.packages.find(lpsPackage => lpsPackage.type === packageType));
    }, [ setSelectedPackageType, packagesData ]);

    const skuCodeToOfferType: { [key: string]: string | undefined } = {
        'SPATT': 'CLIENT_ECOMMERCE_AUTOMATE_FOR_YOURSELF',
        'SPUNATT': 'CLIENT_ECOMMERCE_AUTOMATE_FOR_YOUR_TEAM',
    };

    const isMonthlyPlanType = useMemo(() => currentSkuPackageDetails.planType === 'ANNUAL_SUB_MONTHLY_PAY', [ currentSkuPackageDetails ]);

    const selectedPlanType = useMemo(() => isMonthlyPlanType ? 'ANNUAL_SUB_MONTHLY_PAY' : 'ANNUAL_SUB_ANNUAL_PAY', [ isMonthlyPlanType ]);

    const showUiCustomizationDialog = useShowLearnMoreEcommerceDialog();
    const showDialogCallback = useCallback((packageType: string) => async () => {
        await showUiCustomizationDialog(packageType, skuCodeToOfferType[packageType] ?? '', selectedPlanType);
    }, [ showUiCustomizationDialog, skuCodeToOfferType, selectedPlanType ]);

    const getPriceValue = useCallback(
        (sku: IPackage, planType: string) => {
            let price = 0;
            sku.productQuantities?.forEach(productQuantity => {
                if (currentCurrency) {
                    const foundProductPrice = productsPricesInAllCurrencies?.find(
                        pp => pp.code === productQuantity.code && pp.planType === planType);
                    if (foundProductPrice) {
                        price += productQuantity.quantity * foundProductPrice.prices[currentCurrency];
                    }
                }
            });
            return price;
        },
        [ currentCurrency, productsPricesInAllCurrencies ],
    );

    const getPrice = useCallback(
        (sku: IPackage, planType: string) => {
            const price = getPriceValue(sku, planType);
            return getPriceString(price, currentCurrency, language);
        },
        [ currentCurrency, language, getPriceValue ],
    );

    const presetsOfferSubtitle = useMemo<string>(() => {
        if (skuCodeToOfferType) {
            return Object.keys(skuCodeToOfferType).length > 1
                ? 'CLIENT_ECOMMERCE_PRESETS_OFFERS'
                : 'CLIENT_ECOMMERCE_GET_STARTED_WITH_AUTOMATION_DEVELOPER';
        }

        return 'CLIENT_ECOMMERCE_PRESETS_OFFERS';
    },
    [ skuCodeToOfferType ]);

    const planTypeText: { [key: string]: string } = {
        [MONTHLY_PLAN_TYPE]: 'CLIENT_SLASH_MONTH',
        [ANNUAL_PLAN_TYPE]: 'CLIENT_SLASH_YEAR',
    };

    const price = useMemo (() => currentSkuPackageDetails.prices[currentSkuPackageDetails.planType]?.[currentCurrency],
        [ currentSkuPackageDetails, currentCurrency ]);

    const packagesDataSortedByPrice = useMemo(() => {
        if (!pricesLoading) {
            packagesData?.packages.sort((p1, p2) => (getPriceValue(p1, selectedPlanType) < getPriceValue(p2, selectedPlanType) ? -1 : 1));
            packagesData?.packages.forEach(p => p.productQuantities.sort((p1, p2) => p1.code < p2.code ? -1 : 1));
        }

        return packagesData;
    },
    [ packagesData, pricesLoading, getPriceValue, selectedPlanType ]);

    return {
        packagesData: packagesDataSortedByPrice,
        loading: packagesLoading || pricesLoading,
        selectedPackage,
        currentCurrency,
        currentSkuPackageDetails,
        productsPricesInAllCurrencies,
        selectedPackageType,
        getPrice,
        skuCodeToOfferType,
        planTypeText,
        isMonthlyPlanType,
        onSelectOption,
        price,
        setCurrentCurrency,
        showDialogCallback,
        presetsOfferSubtitle,
    };
};

export default useEcommercePresetsViewModel;
